jQuery(document).ready(function($) {

  //init gsap
  gsap.registerPlugin(ScrollTrigger)
  let mm = gsap.matchMedia();

  //types animation
  if ($('.hotel_type')) {
    ScrollTrigger.create({
      trigger: ".hotel_type",
      start: "center center",
      end: "bottom+=500 center",
      pin: true,
      toggleClass: "active",
    });
  }


  //menu animations
  $('#burger').on('click', ()=>{
    
    if ($('#burger').hasClass('open')) {
      $('#burger').removeClass('open')
      menuTl.reverse()
    } else {
      $('#burger').addClass('open')
      menuTl.play()
    }
  })

  let menuTl = gsap.timeline({
    paused: true,
    onReverseComplete: function() {
      submenus.forEach((submenu, index) => {
        tween[index].reverse();
      });
    }
  });

  mm.add("(min-width: 768.001px)", (context) => {
    menuTl
      .to('.menu_container', {
        translateX: '0%',
        duration: .8,
        ease: 'Power2.inOut',
      })
      .to('.menu_container .content', {
        opacity: 1,
        duration: .3,
        ease: 'Power2.inOut',
        stagger: 0.1
      })
      .to('.menu_container .logo', {
        opacity: 1,
        duration: .3,
        ease: 'Power2.inOut',
        stagger: 0.1
      })
      .to('.menu_container .menu-item', {
        duration: 0.6,
        opacity: 1,
        ease: 'Power2.inOut',
        stagger: 0.1
      }, '-=0.1')
  })

  mm.add("(max-width: 768px)", (context) => {
    menuTl
      .to('.menu_container', {
        translateX: '0%',
        duration: .8,
        ease: 'Power2.inOut',
      })
      .to('.menu_container .content', {
        opacity: 1,
        duration: .3,
        ease: 'Power2.inOut',
        stagger: 0.1
      })
      .to('.menu_container .logo', {
        opacity: 1,
        duration: .3,
        ease: 'Power2.inOut',
        stagger: 0.1
      })
      .to('.menu_container .menu-item', {
        duration: .6,
        opacity: 1,
        ease: 'Power2.inOut',
        stagger: 0.1
      }, '-=0.1')
  })



  //sub menus animations
  var submenus = gsap.utils.toArray('.sub-menu'),
    tween = [];

  submenus.forEach((submenu, index) => {
    tween.push(
      gsap.from(
        submenu,
        {
          duration: 0.25,
          height: 0,
          paused: true,
          reversed: true,
          ease: 'Power2.inOut'
        }
      )
    )
  });
  
  let submenus_triggers = $('#primary-menu').find('a[href="#"]')

  submenus_triggers.each(function(i) {
    $(this).on('click', function(e) {
      e.preventDefault();

      submenus_triggers.each(function(j) {
        if (i != j)
          !tween[j].reversed() && tween[j].reverse();
      })

      tween[i].reversed() ? tween[i].play() : tween[i].reverse();
    });
  });


  //messages animation
  if ($('.messages')) {
    function getMessagesScrollPercentage() {
      let messageElement = document.querySelector('.messages_container')
      if (!messageElement) return 

      let scrollDistance = messageElement.scrollHeight - window.innerHeight

      return ((scrollDistance/window.innerHeight)*100) + 100
    }

    ScrollTrigger.create({
      trigger: ".messages",
      start: "center center",
      end: `bottom+=${getMessagesScrollPercentage()}% center`,
      pin: true,
      // markers: true,
      onUpdate: (e)=>{
        let messageElement = document.querySelector('.messages_container')
        if (!messageElement) return
        
        let scrollDistance = messageElement.scrollHeight - window.innerHeight

        let startMargin = messageElement.offsetHeight / 2
        let distance = e.end - (e.start + startMargin)
        let progress = $(window).scrollTop() - (e.start + startMargin)
        let progressPercentage = progress / distance

        if (progressPercentage > 0 && progressPercentage < 1)
          messageElement.scrollTo(0, progressPercentage * scrollDistance)
        else if (progressPercentage <= 0)
          messageElement.scrollTo(0, 0)
        else
          messageElement.scrollTo(0, scrollDistance)
      },
      onEnter: ()=>{
        $('.messages').addClass('active')
      },
      onLeaveBack: ()=>{
        $('.messages').removeClass('active')
      }
    });    
  }


  // reasons animation
  if ($('.reasons')) {
    let reasons = gsap.utils.toArray(".reason")
    let lastWindowScroll = $(window).scrollTop()
    let animating = false
    let scrollbar = false

    mm.add("(min-width: 768.001px)", (context) => {
      context.add('onScroll', ()=>{

        windowScroll = $(window).scrollTop()


        if (!scrollbar) { //disable if pressing scrollbar
          reasons.forEach(reason=>{
            if(
              windowScroll > $(`#${reason.id}`).offset().top - window.innerHeight
              && windowScroll < $(`#${reason.id}`).offset().top
            ) {
              if (windowScroll > lastWindowScroll) {

                if (!animating) { // disable if already fired
                  let reasonTop = $(`#${reason.id}`).offset().top
                  let spacing = reasonTop - $(window).scrollTop()
                  let multiplier = spacing / window.innerHeight
                  animating = true

                  console.log(windowScroll, lastWindowScroll);

                  let body = $("html, body");
                  body.stop().animate(
                    {
                      scrollTop: reasonTop
                    },
                    500*multiplier,
                    'linear',
                    function() {
                      setTimeout(()=>{
                        animating = false
                      },10)
                    }
                  );
                }

              }
              else if (windowScroll < lastWindowScroll) {

                if (!animating) {
                  let reasonTop = $(`#${reason.id}`).offset().top
                  let spacing = reasonTop - $(window).scrollTop()
                  let multiplier = Math.abs(1 - (spacing / window.innerHeight))
                  animating = true

                  console.log(windowScroll, lastWindowScroll);

                  let body = $("html, body");
                  body.stop().animate(
                    {
                      scrollTop: reasonTop- window.innerHeight
                    },
                    500*multiplier,
                    'linear',
                    function() {
                      setTimeout(()=>{
                        animating = false
                      },10)
                    }
                  );
                }

              }
            }
          })
        }

        lastWindowScroll = windowScroll
      })

      context.add('onMousedown', (e)=>{
        //if difference betweenouter and inner is 0 set it at 12 (windows 11 firefox scrollbar)
        let diff = $(window).outerWidth() - $(window).innerWidth()
        let spacing = diff > 0 ? diff : 12

        if ($(window).outerWidth() - spacing <= e.clientX) {
          scrollbar = true
        }
      })

      context.add('onMouseup', ()=>{ scrollbar = false })

      $(window).on('scroll', context.onScroll)

      $(document).on('mousedown', context.onMousedown)

      $(document).on('mouseup', context.onMouseup)

      return () => {
        $(window).off('scroll', context.onScroll)
        $(document).off('mousedown', context.onMousedown)
        $(document).off('mouseup', context.onMouseup)
      };
    })


    reasons.forEach(reason=>{
      mm.add("(max-width: 768px)", () => {
        ScrollTrigger.create({
          trigger: reason,
          start: "top bottom",
          end: "bottom bottom",
          scrub: 0,
          fastScrollEnd: true,
          snap: {
            snapTo: 1/1,
            duration: 0.1,
            delay: 0,
            ease: "power1.inOut"
          },
          // markers: true,
        });
      })

      ScrollTrigger.create({
        trigger: reason,
        start: `bottom center`,
        end: `bottom+=${window.innerHeight*0.75} center`,
        // markers: true,
        onEnter: ()=>{
          reasonsStage1Tl.play()
          $(reason).addClass('active')
        },
        onLeaveBack: ()=>{
          reasonsStage1Tl.reverse()
          $(reason).removeClass('active')
        },
      })

      ScrollTrigger.create({
        trigger: reason,
        start: `bottom+=${window.innerHeight*0.75} center`,
        end: `bottom+=${window.innerHeight*1.5} center`,
        // markers: true,
        onEnter: ()=>{
          reasonsStage2Tl.play()
        },
        onLeaveBack: ()=>{
          reasonsStage2Tl.reverse()
        },
      })

      mm.add("(min-width: 768.001px)", () => {
        gsap.to(`#${reason.id} .text_fill .filler`, {
          translateY: '-100%',
          ease: 'none',
          scrollTrigger: {
            scrub: 0.5,
            // markers: true,
            trigger: reason,
            start: `bottom+=${window.innerHeight*1.25} center`,
            end: `bottom+=${window.innerHeight*2} center`,
          }
        });      
      })


      let reasonsStage1Tl = gsap.timeline({
        paused: true,
        smoothChildTiming: true,
      }).to(`#${reason.id} .reason_content_first`, {
        opacity: 0,
        duration: .5,
      }).to(`#${reason.id} .reason_content_second .title`, {
        opacity: 1,
        duration: .5
      }, '-=0.5').to([reason, `#${reason.id} .reason_content_second .filler`], {
        backgroundColor:"#35B7AE",
        duration: .5,
      })

      let reasonsStage2Tl = gsap.timeline({
        paused: true,
        smoothChildTiming: true,
      }).to(`#${reason.id} .reason_content_second .content`, {
        opacity: 1,
        duration: .5
      })

    })    
  }


  //experience animation
  if ($('.experience')) {
    mm.add("(min-width: 768.001px)", () => {

      gsap.fromTo('.experience .parallax ', {
        translateY: '-35%',
      },{
        translateY: '35%',
        ease: 'none',
        scrollTrigger: {
          scrub: 0,
          // markers: true,
          trigger: '.experience',
          start: `top center`,
          end: `bottom center`,
        }
      });

    })    
  }


  //room gallery
  if ($('.rooms_gallery')) {
    new Swiper('.room_slider .content_video', {
      loop: true,
      speed: 500,
      allowTouchMove: false,
      effect: 'fade',
      fadeEffect: {
            crossFade: true,
      },
      navigation: {
        nextEl: '#room_next',
        prevEl: '#room_prev',
      },
    });

    new Swiper('.room_slider .content_text', {
      loop: true,
      speed: 500,
      allowTouchMove: false,
      effect: 'fade',
      fadeEffect: {
            crossFade: true,
      },
      navigation: {
        nextEl: '#room_next',
        prevEl: '#room_prev',
      },
    });

    new Swiper('.room_slider .content_right', {
      loop: true,
      speed: 500,
      allowTouchMove: false,
      effect: 'fade',
      fadeEffect: {
            crossFade: true,
      },
      navigation: {
        nextEl: '#room_next',
        prevEl: '#room_prev',
      },
    });

    //room image slide reveal
    gsap.fromTo('.room_slider .reveal-clip',
      {
        clipPath: 'polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)',
      },
      {
        duration: 2,
        clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
        ease: 'Power2.easeOut',
        scrollTrigger: {
          trigger: '.room_slider .reveal-clip img',
          start: 'center+=20% bottom',
          // markers: true,
        }
      }
    );
  }


  //reviews gallery
  if ($('.reviews')) {
    let reviewsIndex = 0
    const reviewsSlides = $('.reviews .hidden')

    $('#review_prev').on('click', ()=>{
      reviewsIndex = reviewsIndex==0 ? reviewsSlides.length-1 : reviewsIndex-1

      gsap.to('.reviews .slide.main', {
        opacity: 0,
        duration: .3,
        onComplete: () => {
          $('.reviews .slide.main').children('.review').html(
            $(reviewsSlides[reviewsIndex]).children('.review').html()
          )

          $('.reviews .slide.main').children('.author').text(
            $(reviewsSlides[reviewsIndex]).children('.author').text()
          )

          gsap.to('.reviews .slide.main', {
            opacity: 1,
            duration: .3,
          })
        }
      })

      gsap.to('.reviews .slide.main', {
        height: $(reviewsSlides[reviewsIndex]).innerHeight(),
        duration: .5,
        ease: 'none',
      })
    })

    $('#review_next').on('click', ()=>{
      reviewsIndex = reviewsIndex==reviewsSlides.length-1 ? 0 : reviewsIndex+1
      console.log('aaaaaaaa');

      gsap.to('.reviews .slide.main', {
        opacity: 0,
        duration: .3,
        onComplete: () => {
          $('.reviews .slide.main').children('.review').html(
            $(reviewsSlides[reviewsIndex]).children('.review').html()
          )

          $('.reviews .slide.main').children('.author').text(
            $(reviewsSlides[reviewsIndex]).children('.author').text()
          )

          gsap.to('.reviews .slide.main', {
            opacity: 1,
            duration: .3,
          })
        }
      })

      gsap.to('.reviews .slide.main', {
        height: $(reviewsSlides[reviewsIndex]).innerHeight(),
        duration: .5,
        ease: 'none',
      })
    })

    $('.reviews .slide.main').css('height', $(reviewsSlides[reviewsIndex]).innerHeight())
    $(window).on('resize', ()=>{
      $('.reviews .slide.main').css('height', $(reviewsSlides[reviewsIndex]).innerHeight())
    })    
  }



  // fade animations
  let fades = gsap.utils.toArray('.fade');

  if ( fades.length > 0 ) {
    fades.forEach((text) => {
      let delay = text.getAttribute('data-delay') || 0
      let start = text.getAttribute('data-start') || 'top bottom'

      gsap.from(
        text,
        {
          delay: delay,
          duration: 1,
          autoAlpha: 0,
          scrollTrigger: {
            trigger: text,
            start: start,
          }
        }
      );
    });
  }

  // fade up animations
  let texts = gsap.utils.toArray('.fade-up');

  if ( texts.length > 0 ) {
    texts.forEach((text) => {
      let delay = text.getAttribute('data-delay') || 0
      let start = text.getAttribute('data-start') || 'top bottom'

      gsap.from(
        text,
        {
          delay: delay,
          duration: 1,
          autoAlpha: 0,
          y: 45,
          scrollTrigger: {
            trigger: text,
            start: start,
          }
        }
      );
    });
  }
})